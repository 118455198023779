<i18n>
  {
    "es": {
      "CLOSE": "Cerrar"
    }
  }
</i18n>

<template>
  <div
    class="bg-white pointer-events-auto mt-4 w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
  >
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <div
            v-if="props.message.type === 'error'"
            class="bg-white rounded-full p-2"
          >
            <img
              src="@/assets/svg/icons/icons-error-snackbar.svg"
              alt="icono de error"
              class="h-9 w-9 text-black"
            />
          </div>
          <div
            v-if="props.message.type === 'success'"
            class="bg-white rounded-full p-2"
          >
            <img
              src="@/assets/svg/icons/icons-success-snackbar.svg"
              alt="icono de éxito"
              class="h-9 w-9 text-black"
            />
          </div>
          <div
            v-if="props.message.type === 'info'"
            class="bg-white rounded-full p-2"
          >
            <img
              src="@/assets/svg/icons/icons-info-snackbar.svg"
              alt="icono de información"
              class="h-9 w-9 text-black"
            />
          </div>
          <div
            v-if="props.message.type === 'warning'"
            class="bg-white rounded-full p-2"
          >
            <img
              src="@/assets/svg/icons/icons-warning-snackbar.svg"
              alt="icono de warning"
              class="h-9 w-9 text-black"
            />
          </div>
        </div>
        <div class="ml-3 w-0 flex-1 pt-2">
          <p class="font-semibold text-sanremo-black tracking-wide text-sm">
            {{ message.result }}
          </p>
          <p class="font-normal text-sanremo-gray tracking-wide text-xs mt-2">
            {{ message.text }}
          </p>
        </div>
        <div class="ml-4 flex flex-shrink-0 pt-4">
          <button
            @click="remove"
            class="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
          >
            <span class="sr-only">{{ t('CLOSE') }}</span>
            <img
              src="@/assets/svg/icons/icons-close-snackbar.svg"
              alt="icono de cerrar"
              class="h-5 w-5 text-black"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  message: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n({
  useScope: 'local',
})

const snackBar = useSnackBar()

const remove = () => {
  snackBar.removeMessage(props.message.id)
}
</script>
